<template>
  <Layout class="MachineLogWrapper">
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
        :rules="rules"
        class="log-form"
        size="mini"
      >
        <el-form-item prop="dateRange">
          <el-date-picker
            v-model="formData.dateRange"
            type="daterange"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item prop="appId">
          <el-select
            v-model="formData.app_key"
            placeholder="选择APP"
            collapse-tags
            clearable
          >
            <el-option
              v-for="(item, index) in appKeysList"
              :key="index"
              :label="item.name"
              :value="item.app_key"
            />
            <el-option
              label="其他"
              value="other"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="version">
          <el-input
            v-model="formData.version"
            placeholder="版本号"
            clearable
          />
        </el-form-item>
        <el-form-item prop="countryCodeList">
          <el-select
            v-model="formData.countryCodeList"
            style="width: 240px"
            multiple
            clearable
            filterable
            :filter-method="filterMethod"
            collapse-tags
            value-key="countryCode"
            placeholder="Country"
            @visible-change="filterNationList = nationList"
          >
            <div class="pre-header">
              <div
                class="select-all"
                @click="changeSelectState('countryCodeList', nationList, formData)"
              >
                {{ selectButtonLabel(formData.countryCodeList, nationList) }}
              </div>
            </div>
            <el-option
              v-for="item in filterNationList"
              :key="item.countryCode"
              :label="item.nation"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="payType">
          <el-select
            v-model="formData.channelList"
            style="width: 308px"
            multiple
            clearable
            filterable
            collapse-tags
            placeholder="Channel"
          >
            <div class="pre-header">
              <div
                class="select-all"
                @click="changeSelectState('channelList', channelList.map(item => item.value), formData)"
              >
                {{ selectButtonLabel(formData.channelList, channelList) }}
              </div>
            </div>
            <el-option
              v-for="(item, index) in channelList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <template v-slot:right>
        <div style="width: 180px">
          <el-row>
            <el-col :span="12">
              <el-button
                type="primary"
                @click="handleSearch"
              >
                搜索
              </el-button>
            </el-col>
            <!-- <el-button
              type="primary"
              @click="handleSearch"
            >
              搜索
            </el-button> -->
            <el-col :span="12">
              <download-excel
                :data="exportParam.data"
                :fields="exportParam.fields"
                :type="exportParam.type"
                :worksheet="exportParam.worksheet"
                :name="exportParam.filename"
              >
                <el-button type="primary">
                  导出
                </el-button>
              </download-excel>
            </el-col>
          </el-row>
        </div>
      </template>
    </BHeader>
    <div
      v-loading="tableData.loading"
      class="overview"
    >
      <div class="items">
        <div class="num">
          {{ numTotal || '0' }}
        </div>
        <div class="name">
          订单总数
        </div>
      </div>
      <div class="items">
        <div class="num">
          {{ amountTotal || '0' }}
        </div>
        <div class="name">
          订单总金额
        </div>
      </div>
      <div class="items">
        <div class="num">
          {{ successNumTotal || '0' }}
        </div>
        <div class="name">
          成功支付订单总数
        </div>
      </div>
      <div class="items">
        <div class="num">
          {{ successAmountTotal || '0' }}
        </div>
        <div class="name">
          订单成功支付总金额
        </div>
      </div>
      <div class="items">
        <div class="num">
          {{ isNaN(successNumTotal * 100 / numTotal) ?'0' :(successNumTotal * 100 / numTotal).toFixed(2) }}%
        </div>
        <div class="name">
          订单支付转化率
        </div>
      </div>
    </div>
    <el-table
      v-loading="tableData.loading"
      :data="tableData.list"
      style="width: 100%"
      :row-class-name="tableRowClassName"
    >
      <el-table-column
        type="expand"
      >
        <template slot-scope="scope">
          <el-table
            :data="scope.row.by_payment_method"
            :row-class-name="tableRowClassName"
            class="small-table"
            :header-cell-style="{
              background:'#e6e6e6'}"
          >
            <el-table-column
              prop="pay_type"
              label="支付方式"
            >
              <template slot-scope="childrenRow">
                {{ channelMap[childrenRow.row.pay_type] || childrenRow.row.pay_type }}
              </template>
            </el-table-column>
            <el-table-column
              prop="order_created"
              label="订单总数"
            />
            <el-table-column
              prop="created_amount"
              label="订单总金额"
            >
              <template slot-scope="childrenRow">
                {{ (childrenRow.row.created_amount) == Infinity?"100":(childrenRow.row.created_amount) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="order_paid"
              label="成功支付订单总数"
            />
            <el-table-column
              prop="paid_amount"
              label="订单总金额"
            >
              <template slot-scope="childrenRow">
                {{ (childrenRow.row.paid_amount) == Infinity?"100":(childrenRow.row.paid_amount) }}
              </template>
            </el-table-column>
            <el-table-column
              label="订单支付转化率"
            >
              <template slot-scope="childrenRow">
                {{ (childrenRow.row.order_paid / childrenRow.row.order_created *100) == Infinity?"100":(childrenRow.row.order_paid / childrenRow.row.order_created *100).toFixed(2) }} %
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
            >
              <template slot-scope="childrenRow">
                <el-button
                  type="text"
                  @click="clickDetail(scope.row.date,childrenRow.row.pay_type)"
                >
                  详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
        prop="date"
        label="日期"
      />
      <el-table-column
        prop="order_created"
        label="订单总数"
      />
      <el-table-column
        prop="created_amount"
        label="订单总金额"
      >
        <template slot-scope="scope">
          {{ (scope.row.created_amount) == Infinity?"100":(scope.row.created_amount) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="order_paid"
        label="成功支付订单总数"
      />
      <el-table-column
        prop="paid_amount"
        label="订单总金额"
      >
        <template slot-scope="scope">
          {{ (scope.row.paid_amount) == Infinity?"100":(scope.row.paid_amount) }}
        </template>
      </el-table-column>
      <el-table-column
        label="订单支付转化率"
      >
        <template slot-scope="scope">
          {{ (scope.row.order_paid / scope.row.order_created *100) == Infinity?"100":(scope.row.order_paid / scope.row.order_created *100).toFixed(2) }} %
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      v-loading="detailTable.loading"
      :title="detailTable.title"
      :visible.sync="detailTable.visible"
    >
      <el-table :data="detailTable.list">
        <el-table-column
          prop="nation_code"
          label="国家"
        >
          <template slot-scope="scope">
            {{ countryCodeMap[scope.row.nation_code].value || scope.row.nation_code }}
          </template>
        </el-table-column>
        <el-table-column
          prop="order_created"
          label="订单总数"
        />
        <el-table-column
          prop="created_amount"
          label="订单总金额"
        >
          <template slot-scope="scope">
            {{ (scope.row.created_amount) == Infinity?"100":(scope.row.created_amount) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="order_paid"
          label="成功支付订单总数"
        />
        <el-table-column
          prop="paid_amount"
          label="订单总金额"
        >
          <template slot-scope="scope">
            {{ (scope.row.paid_amount) == Infinity?"100":(scope.row.paid_amount) }}
          </template>
        </el-table-column>
        <el-table-column
          label="订单支付转化率"
        >
          <template slot-scope="scope">
            {{ (scope.row.order_paid / scope.row.order_created *100) == Infinity?"100":(scope.row.order_paid / scope.row.order_created *100).toFixed(2) }} %
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </Layout>
</template>

<script>
import { countryCodeList } from '@/utils/selectOptions'
import { paymentMethodConversionRate, paymentMethodConversionRateDetailByPayTypeAndDate } from '@/api/product-operation.js'
import { cloneDeep } from 'lodash'
import { getUserNationStatistics } from '@/api/electronicEntity.js'
// import { getAppKeys } from '@/api/blacklist.js'
import { commonRequestGet } from '@/api/commonApi.js'
import { format } from 'fecha'

const getLastWeek = () => {
  const days = 7
  const targetDay = new Date((Date.now() - days * 24 * 60 * 60 * 1000)).getTime()
  return [format(targetDay, 'YYYY-MM-DD'), format(new Date((Date.now())).getTime(), 'YYYY-MM-DD')]
}

const countryCodeMap = countryCodeList.reduce((map, item) => {
  map[item.name] = item
  return map
}, {})
const formKey = 'FORM'
export default {
  name: 'PaidConversionRate',
  data () {
    return {
      channelList: [
        {
          name: 'GooglePlay',
          value: 1
        },
        {
          name: 'IOS内购',
          value: 2
        },
        {
          name: 'PayPal',
          value: 3
        },
        {
          name: 'Stripe',
          value: 4
        },
        {
          name: 'Alipay',
          value: 5
        },
        {
          name: 'AirWallex',
          value: 6
        },
        {
          name: 'PayerMax',
          value: 7
        },
        {
          name: 'Wechat',
          value: 8
        }
      ],
      channelMap: {
        1: 'GooglePlay',
        2: 'IOS内购',
        3: 'PayPal',
        4: 'Stripe',
        5: 'CenterPay',
        6: 'AirWallex',
        7: 'PayerMax'
      },
      detailTable: {
        title: '',
        visible: false,
        loading: false,
        list: []
      },
      nationList: [],
      filterNationList: [],
      countryCodeList,
      countryCodeMap,
      appKeysList: [],
      tableData: {
        loading: false,
        list: []
      },
      tableDataTotal: {
        paidAmountTotal: 0.0
      },
      formData: {
        dateRange: getLastWeek(),
        countryCodeList: [],
        // pageNum: 1,
        // pageSize: 20,
        channelList: []
      },
      rules: {
        dateRange: [
          { required: true, message: 'Date range is required' }
        ]
      },
      pickerOptions: {
        disabledDate (time) {
          const dateTime = time.getTime()
          return dateTime > new Date((Date.now())).getTime()
        }
      },
      exportParam: {
        fields: {
          日期: 'date',
          订单总数: 'order_created',
          订单总金额: 'created_amount',
          成功支付订单总数: 'order_paid',
          成功支付订单总金额: 'paid_amount',
          订单支付转化率: 'subscription_amount'
        },
        data: [],
        filename: '支付付费转化率',
        worksheet: 'sheet1',
        type: 'xls'
      }
    }
  },
  computed: {
    'numTotal' (res) {
      var num = 0
      res.tableData.list.forEach(element => {
        num += element.order_created * 1
      })
      return num
    },
    'successNumTotal' (res) {
      var num = 0
      res.tableData.list.forEach(element => {
        num += element.order_paid * 1
      })
      return num
    },
    'amountTotal' (res) {
      var num = 0
      res.tableData.list.forEach(element => {
        num += element.created_amount * 1
      })
      return (num).toFixed(2)
    },
    'successAmountTotal' (res) {
      var num = 0
      res.tableData.list.forEach(element => {
        num += element.paid_amount * 1
      })
      return (num).toFixed(2)
    }
  },
  watch: {
    'tableData.list': {
      handler: function () {
        var data = []
        this.tableData.list.forEach(element => {
          element.subscription_amount = (element.order_paid / element.order_created * 100).toFixed(2) + '%'
          data.push(element)
        })
        this.exportParam.data = data
      }
    }
  },
  mounted () {
    this.handleSearch()
    this.getAppKeys()
  },
  created () {
    this.queryNationList()
      .then(() => {
        const storageFormData = sessionStorage.getItem(formKey)
        if (storageFormData) {
          this.formData = JSON.parse(storageFormData)
        }
        this.queryDataList()
      })
  },
  methods: {
    tableRowClassName ({ row, rowIndex }) {
      const num = (row.paid_num / row.active_num * 100).toFixed(2)
      if (num <= 0.5) {
        return 'red-row'
      } else if (num >= 1) {
        return 'green-row'
      }
      return ''
    },
    handleSearch () {
      this.tableData.loading = true
      this.queryDataList()
    },
    computedIp (connectInfo) {
      const info = JSON.parse(connectInfo || '{}')
      return ((info.data || {}).server_info || {}).innerip || '/'
    },
    computedTime (second) {
      const min = Math.floor(second % (60 * 60))
      const s = ('0' + second % 60).slice(-2)
      const m = ('0' + Math.floor(min / 60)).slice(-2)
      const H = Math.floor(second / (60 * 60))
      const h = H > 9 ? H : '0' + H
      return `${h}:${m}:${s}`
    },
    handleExport () {
    },
    changeSelectState (listKey, fullList, scope) {
      scope[listKey].length === fullList.length
        ? scope[listKey] = []
        : scope[listKey] = fullList
    },
    selectButtonLabel (currentList, fullList) {
      return currentList.length === fullList.length ? '取消全选' : '全选'
    },
    // formatQuery (formData) {
    //   const form = cloneDeep(formData)
    //   const [startTime, endTime] = form.dateRange
    //   delete form.dateRange
    //   return {
    //     version: form.version,
    //     start_time: startTime,
    //     end_time: endTime,
    //     nationCode: form.countryCodeList.map(item => {
    //       return item.countryCode
    //     }),
    //     payType: form.channelList
    //   }
    // },
    formatQuery (formData) {
      const form = cloneDeep(formData)
      const [startTime, endTime] = form.dateRange
      delete form.dateRange
      return {
        ...form,
        start_time: startTime,
        end_time: endTime,
        // app_key: form.appKey,
        nation_code: form.countryCodeList.map(item => {
          return item.countryCode
        }),
        pay_type: form.channelList
      }
    },
    queryNationList () {
      return getUserNationStatistics({
        searchWord: '',
        pageNum: 1,
        pageSize: 1000
      }).then(res => {
        if (res.code === 200) {
          const sortNationList = (res.data.list || []).sort((a, b) => {
            if (a.nation < b.nation) {
              return -1
            }
            if (a.nation > b.nation) {
              return 1
            }
            return 0
          })
          this.nationList = sortNationList
          this.filterNationList = sortNationList
          this.formData.countryCodeList = this.nationList
        }
      })
    },
    filterMethod (keyword) {
      this.filterNationList = keyword ? this.nationList.filter(item => {
        return item.nation.toLowerCase().startsWith(keyword.toLowerCase())
      }) : this.nationList
    },
    async getAppKeys () {
      const res = await commonRequestGet('admin/app-review', this.formData)
      // console.log(res, '1111111')
      this.appKeysList = res?.data ?? []
      // 当排序设置为0时，不在应用下拉框内显示
      this.filteredAppKeysList = this.appKeysList.filter(item => item.rank !== 0)
      this.appKeysList = this.filteredAppKeysList.map(item => {
        return {
          app_key: item.app_id,
          name: item.app_name
        }
      })
      this.appKeysNameMap = this.appKeysList.reduce((result, item) => {
        result[item.app_key] = item.name
        return result
      }, {})
    },
    queryDataList () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const query = this.formatQuery(this.formData)
          delete query.countryCodeList
          sessionStorage.setItem(formKey, JSON.stringify(this.formData))
          paymentMethodConversionRate(query)
            .then(res => {
              this.tableData.list = res || []
            })
            .finally(() => {
              this.tableData.loading = false
            })
        }
      })
    },
    detail (date, payType) {
      this.$refs.form.validate(valid => {
        if (valid) {
          const query = this.formatQuery(this.formData)
          query.startTime = date
          query.payType = [payType * 1]
          sessionStorage.setItem(formKey, JSON.stringify(this.formData))
          paymentMethodConversionRateDetailByPayTypeAndDate(query)
            .then(res => {
              if (res.code === 200) {
                this.detailTable.list = res.data.list || []
                // this.listInfo.total = res.data.total || 0
              }
            })
            .finally(() => {
              this.detailTable.loading = false
            })
        }
      })
    },
    clickDetail (date, payType) {
      this.detailTable.title = date + '  (' + this.channelMap[payType] + ')'
      this.detailTable.list = []
      this.detailTable.visible = true
      this.detailTable.loading = true
      this.detail(date, payType)
    },
    expandClick () {
    }
  }
}
</script>

<style lang="less">
  .MachineLogWrapper{
    .el-table__expanded-cell{
      background-color: #FAFCFD;
      .table-expand{
        label {
          width: 100px;
          color: #99a9bf;
        }
        .el-form-item{
          margin-bottom: 0;
        }
      }
    }
  }
</style>
<style scoped lang="less">
 .el-table /deep/ .red-row {
    color: red;
  }

  .el-table /deep/ .green-row {
    color: green;
  }
  .small-table /deep/ .el-table__row{
    background-color: #e6e6e6;
  }
  .log-form{
    .el-input,
    .el-select{
      width: 150px;
    }
    .el-date-editor--daterange.el-input,
    .el-date-editor--daterange.el-input__inner,
    .el-date-editor--timerange.el-input,
    .el-date-editor--timerange.el-input__inner {
      width: 270px;
    }
  }
  .pre-header {
      height: 46px;
      .select-all {
        position: absolute;
        top: 0;
        z-index: 100;
        width: 100%;
        font-size: 14px;
        margin: 10px 0;
        padding: 10px 20px;
        cursor: pointer;
        background: #F5F7FA;
        box-sizing: border-box;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, .12);
        &::before {
          content: '';
          display: block;
          height: 10px;
          background: white;
          position: absolute;
          top: -10px;
          left: 0;
          width: 100%;
        }
        &:hover {
          color: cornflowerblue;
        }
      }
    }
    .overview {
    display: flex;
    // padding: 0 0 20px 0;
    min-width: 880px;
    margin-bottom: 10px;
    .items {
      // width: 210px;
      padding: 0 30px;
      height: 100px;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, .12);
      border-radius: 4px;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .num {
        font-size: 26px;
        margin-bottom: 10px;
        .unit {
          font-size: 14px;
        }
      }
      .name {
        font-size: 12px;
      }
      .pre-header {
      height: 46px;
      .select-all {
        position: absolute;
        top: 0;
        z-index: 100;
        width: 100%;
        font-size: 14px;
        margin: 10px 0;
        padding: 10px 20px;
        cursor: pointer;
        background: #F5F7FA;
        box-sizing: border-box;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, .12);
        &::before {
          content: '';
          display: block;
          height: 10px;
          background: white;
          position: absolute;
          top: -10px;
          left: 0;
          width: 100%;
        }
        &:hover {
          color: cornflowerblue;
        }
      }
    }
    }
    }
</style>
